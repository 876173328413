import { render, staticRenderFns } from "./PostGrid.vue?vue&type=template&id=2d5aedc3&scoped=true&"
import script from "./PostGrid.vue?vue&type=script&lang=js&"
export * from "./PostGrid.vue?vue&type=script&lang=js&"
import style0 from "@/styles/components/_post-grid.scss?vue&type=style&index=0&id=2d5aedc3&scoped=true&lang=scss&"
import style1 from "@/styles/components/_tease.scss?vue&type=style&index=1&id=2d5aedc3&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d5aedc3",
  null
  
)

export default component.exports
<template>
    <div class="c-post-grid" :class="cssClass">
        <slot name="title"></slot>
        <div class="o-container o-container--full o-container--no-pad">
            <div class="o-layout o-layout--multiline" :class="`o-layout--${ postAmount }`">
                <router-link v-for="(p, j) in allPosts" :to="`/post/${p.post_name}`" class="o-layout__item c-tease c-tease--grid" :key="j">
                        <slot :post="p"></slot>
                        <span class="c-tease__img"
                              :style="{ backgroundImage: `url(${ p.featured_image ? p.featured_image.sizes.large.src[0] : '' })`, backgroundPosition: p.featured_image_position ? p.featured_image_position.replace(',', ' ') : null }"></span>
                    <span class="c-tease__subtitle">{{ p.post_tagline }}</span>
                    <h2 class="c-tease__title">{{ p.post_title }}</h2>
                </router-link>
            </div>
        </div>
        <button v-if="!allPostsLoaded" class="c-btn c-btn--load-more c-txt--upper" v-touch:tap="loadMore">
            Mehr Laden
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.669 1l-6.5 6-6.5-6"/></svg>
        </button>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'

    export default {
        name: 'PostGrid',
        props: {
            posts: Array,
            title: String,
            cssClass: String,
            postAmount: {
                type: Number,
                default: 3
            }
        },

        watch: {
            posts: function (r) {
                this.allPosts = r.slice(0, this.postAmount);
            }
        },

        data () {
            return {
                allPosts: []
            }
        },

        beforeMount () {
            this.resetPosts()
        },

        methods: {
            ...mapActions({
                loadMorePosts: 'loadMorePosts'
            }),

            resetPosts () {
                this.allPosts = this.posts.slice(0, this.postAmount)
            },

            loadMore () {
                const arrLength = this.allPosts.length
                const morePosts = this.posts.slice(arrLength, arrLength + this.postAmount)
                this.allPosts = this.allPosts.concat(morePosts)
                this.loadMorePosts()
            }
        },

        computed: {
            allPostsLoaded() {
                return this.allPosts.length === this.posts.length
            }
        }
    }
</script>

<style scoped lang="scss" src="@/styles/components/_post-grid.scss"></style>
<style scoped lang="scss" src="@/styles/components/_tease.scss"></style>